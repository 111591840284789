<template>
  <div>
    <!-- Queremos conectar las empreas -->
    <section id="we_cant_to_coneect" class="section-top-contend">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-sm-12 col-md-offset-1 text-left">
                    <div class="title" itemscope itemtype="https://handelbay.com/">
                        <h2 class="title-1" itemprop="HandelBay Innvador">
                            {{ $t('lang.free_trial.we_want_to_connect_companies') }}
                        </h2>
                        <h3 class="title-2" itemprop="HandelBay en tu día a día">
                            {{ $t('lang.free_trial.worldwide') }}
                        </h3>
                        <span class="handelbay-active"></span>
                    </div>
                </div>
            </div>
            <!-- .row -->
        </div>
        <!-- .container -->
    </section>
    <!-- End queremos conectar las empresas -->

    <!-- Por qué -->
    <section id="why_handelbay">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-sm-12 col-md-offset-1 z-index text-left">
                    <div class="title" itemscope itemtype="https://handelbay.com/">                        
                        <h2 class="title-2" itemprop="HandelBay en tu día a día">
                            {{ $t('lang.free_trial.what_motivates_us?') }}
                        </h2>
                        <span class="handelbay-active"></span>
                    </div>
                    <div class="handelbay-text" itemscope itemtype="https://handelbay.com/">
                        <p itemprop="HandelBay">
                            {{ $t('lang.free_trial.we_are_convinced_that_the_future_of_supply_is_given_by_the_dynamics_of_online_commerce') }}
                            {{ $t('lang.free_trial.in_which_the_optimization') }}
                            {{ $t('lang.free_trial.transparency_and_agility_are_the_main_parameters_in_entrepreneurial_business') }}
                        </p>                       
                    </div>
                </div>
            </div>
            <!-- .row -->
            <div class="row">
                <div class="col-md-11 col-md-ofsset-1">
                    <div class="img_provider_and_buyer">
                        <img src="../assets/images/landing/handelbay/handelbay_proveedor_comprador.png" alt="Proveedor y comprador">
                    </div>                    
                </div>
            </div>
            <!-- .row -->

            <div class="row">
                <div class="col-md-6 col-sm-12 col-md-offset-5 z-index text-right text-bottom">
                    <div class="title" itemscope itemtype="https://handelbay.com/">                        
                        <h3 class="title-2" itemprop="HandelBay en tu día a día">
                            {{ $t('lang.free_trial.how_do_we_do_it?') }}
                        </h3>
                        <span class="handelbay-active"></span>
                    </div>
                    <div class="handelbay-text" itemscope itemtype="https://handelbay.com/">
                        <p itemprop="HandelBay">
                            {{ $t('lang.free_trial.through_a_user_centered_design') }} 
                            {{ $t('lang.free_trial.best_development_practices_and_permanent_innovation') }} 
                            {{ $t('lang.free_trial.we_create_a_system_available_in_the_cloud') }} 
                            {{ $t('lang.free_trial.(SaaS_-_Software_as_a_Service)_that_brings_together_buyers_with_suppliers_through_a_social_network_and_shopping_platform') }} 
                            {{ $t('lang.free_trial.called_HandelBay') }}
                        </p>                       
                    </div>
                </div>
            </div>
             <!-- .row -->
        </div>
        <!-- .container -->
    </section>
    <!-- end por qué -->

    <!-- .handelbay-background-curva -->
    <section id="handelbay-background-curva">

    </section>
    <!-- .handelbay-background-curva -->

    <!-- Que hacemos -->
    <section id="what_do_we_do">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-sm-7 col-xs-12 col-md-offset-1 text-left">
                    <div class="title" itemscope itemtype="https://handelbay.com/">
                        <h2 class="title-2" itemprop="HandelBay en tu día a día">
                            {{ $t('lang.free_trial.what_do_we_do?') }}
                        </h2>
                        <span class="handelbay-active"></span>
                    </div>
                    <div class="handelbay-text" itemscope itemtype="https://handelbay.com/">
                        <p itemprop="HandelBay es la solución">
                            {{ $t('lang.free_trial.through_this_tool') }} 
                            {{ $t('lang.free_trial.and_depending_on_the_type_of_profile_(buyer_supplier_or_dual)') }} 
                            {{ $t('lang.free_trial.companies_can_internally_manage_their_business_purchases_and_publish_their_purchasing_needs') }} 
                            {{ $t('lang.free_trial.not_only_to_its_current_suppliers_but_also_to_a_constantly_growing_network_of_suppliers_nationwide') }}
                        </p>
                        <p itemprop="HandelBay te permite optimizar">
                            {{ $t('lang.free_trial.additionally_they_can_participate_and_quote_in_the_purchase_processes_published_by_the_network_of_existing_buyers_in_HandelBay') }}
                        </p>

                        <p itemprop="HandelBay te permite optimizar" class="text-bold-handelbay">
                            <strong>
                                {{ $t('lang.free_trial.HandelBay_is_a_business_purchasing_platform_(B2B_marketplace)_that_connects_potential_buyers_with_competitive_suppliers_through_a_business_social_network') }}
                            </strong>
                        </p>
                    </div>
                </div>
            </div>
            <!-- .row -->
        </div>
        <!-- .container -->
    </section>
    <!-- End Que hacemos -->

    <!-- .handelbay-background-curva -->
    <section id="handelbay-background-curva-bottom">
    </section>
    <!-- .handelbay-background-curva -->

    <!-- Ignite -->
    <section id="ignite">
        <div class="container">
            <div class="row">
                <div class="col-md-10 col-sm-12 col-md-offset-1" itemscope>
                    <div class="title-ignite">
                        <div class="row">
                            <div class="col-md-6 col-md-offset-3 col-sm-6 col-sm-offset-3">
                                <a href="http://www.ignite.com.co" target="_blank">
                                    <img src="../assets/images/landing/handelbay/handelbay_ignite.png" alt="Ignite technologies">
                                </a>
                            </div>
                        </div>
                    </div>
                    <p itemprop="HandelBay diseño creado por Ignite Technologies S.A.S">
                        {{ $t('lang.free_trial.HandelBay_is_a_product_designed_and_created_by_Ignite_Technologies_S.A.S_a_company_dedicated_to_the_design_and_development_of_innovation_products_in_technology_engineering_and_science') }}
                    </p>
                    <div class="row">
                        <div class="col-md-4 col-sm-4 no-p-r no-border-ignite">
                            <div class="tecno-ignite text-center">
                                <div class="brand">
                                    <img src="../assets/images/landing/handelbay/handelbay_brand1.png" alt="Tecnología">
                                </div>
                                <h2 class="title">
                                    {{ $t('lang.free_trial.technology') }}
                                </h2>
                                <p>
                                    {{ $t('lang.free_trial.we_are_convinced_that_technology_is_the_best_way_for_our_clients_to_achieve_a_greater_impact_on_their_business_in_less_time') }}
                                </p>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 no-p-r no-p-l no-border-ignite">
                            <div class="tecno-ignite text-center">
                                <div class="brand">
                                    <img src="../assets/images/landing/handelbay/handelbay_brand2.png" alt="Tecnología">
                                </div>
                                <h2 class="title">
                                    {{ $t('lang.free_trial.engineering') }}
                                </h2>
                                <p>
                                    {{ $t('lang.free_trial.it_is_our_main_tool_to_face_the_problems_and_needs_of_our_clients_and_to_be_able_to_design_and_deliver_the_best_solutions') }}
                                </p>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4 no-p-l no-border-ignite">
                            <div class="tecno-ignite text-center">
                                <div class="brand">
                                    <img src="../assets/images/landing/handelbay/handelbay_brand3.png" alt="Tecnología">
                                </div>
                                <h2 class="title">
                                    {{ $t('lang.free_trial.science') }}
                                </h2>
                                <p>
                                    {{ $t('lang.free_trial.we_articulate_the_skills_of_our_team_to_design_solutions_that_allow_rationalizing_the_use_of_renewable_natural_resources') }} 
                                    {{ $t('lang.free_trial.replacing_them_with_alternative_systems_or_resources') }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- .row -->
        </div>
        <!-- .container -->

    </section>
    <!-- End Ignite -->
    <PorqueHandelbay />

  </div>
</template>
<script>
import PorqueHandelbay from '@/components/PorqueHandelbay'

export default {
    components:{
        PorqueHandelbay: PorqueHandelbay
    }
}
</script>
